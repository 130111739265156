var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$account.isFullAdmin || this.$account.permissions.canViewAllMessages === true)?_c('div',{staticClass:"messages"},[_c('v-dialog',{attrs:{"width":"400","max-width":"400"},model:{value:(_vm.assignAgentConfirmation),callback:function ($$v) {_vm.assignAgentConfirmation=$$v},expression:"assignAgentConfirmation"}},[_c('v-form',{ref:"assignAgentConfirmationForm",on:{"submit":function($event){$event.preventDefault();return _vm.assignAgentConfirmationSubmit()}}},[_c('v-card',[_c('v-card-title',[_vm._v("Confirmation")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"add-role-col",attrs:{"cols":"12"}},[_vm._v(" Are you sure you want to assign this? ")])],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end pa-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","x-large":"","color":"primary"},on:{"click":function($event){_vm.assignAgentConfirmation = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"x-large":"","color":"primary","type":"submit","disabled":_vm.loading,"loading":_vm.loading}},[_vm._v("Ok")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.messagesList,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phoneNumber)+" ")]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullName)+" ")]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message.subject)+" ")]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"120px"}},[_vm._v(" "+_vm._s(item.message.html)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.to)+" ")]}},{key:"item.agentId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"210px"}},[_c('v-select',{staticClass:"rounded-xl",attrs:{"disabled":!_vm.isFullAdmin && !_vm.canShowSuggestedAgentInMessagesScreen,"rounded":"","filled":"","hide-details":"","item-text":"userName","item-value":"id","items":_vm.agentList,"dense":""},on:{"change":function($event){return _vm.updateField(item)}},model:{value:(_vm.messagesList[_vm.messagesList.map(function (i) { return i.id; }).indexOf(item.id)].agentId),callback:function ($$v) {_vm.$set(_vm.messagesList[_vm.messagesList.map(function (i) { return i.id; }).indexOf(item.id)], "agentId", $$v)},expression:"messagesList[messagesList.map(i => i.id).indexOf(item.id)].agentId"}})],1)]}},(this.$account.isFullAdmin || this.$account.permissions.canShowSuggestedAgentInMessagesScreen === true)?{key:"item.suggestedAgent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.suggestedAgent)+" ")]}}:null,{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.timestamp))+" ")]}}],null,true)})],1):_c('div',[_c('v-sheet',{staticClass:"pa-4",attrs:{"color":"white"}},[_vm._v(" You are not allowed on this page... ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }